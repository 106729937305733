import {ComponentProps, forwardRef} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 10px;
  min-width: 270px;
  grid-area: ${(props) => (props.style?.gridArea ? props.style?.gridArea : '')};
`;

const Input = styled.input<InputProps>`
  border: 1px solid
    ${(props) =>
      props.error
        ? props.theme.colors.warning600
        : props.theme.colors.neutral300};
  border-radius: 0.7rem;
  height: 44px;
  margin-top: ${(props) => props.theme.spacing.s}px;
  padding: 5px 10px;
  font-size: 0.875rem;
  line-height: 1.3125;
  font-weight: 500;
  opacity: ${(p) => (p.disabled ? 0.5 : 1)};
  color: ${(props) => props.theme.colors.neutral800};
`;

const Label = styled.label`
  padding-left: 5px;
`;

// or #E98F8F for background - so its not see through
export const ErrorContainer = styled.div<ErrorProps>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  margin: ${(props) => props.theme.spacing.s}px 0;
  align-items: center;
  //min-height: 1.125rem;
  position: absolute;
  right: 0;
  background: #f29b9b;
  box-shadow: 0px 2px 4px rgba(17, 24, 39, 0.08);
  border-radius: 24px;
  width: 236px;
  min-height: ${(p) => (p.noHeight ? 'unset' : '42px')};
  top: ${(p) => (p.noHeight ? 'unset' : '-31px')};
  bottom: ${(p) => (p.noHeight ? '50px' : 'unset')};
  padding: 0 10px;
  z-index: 9999;
  max-width: 100%;
`;
const HintContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 5px;
`;
export const ErrorTriangle = styled.div`
  z-index: 999;
  position: absolute;
  left: 30px;
  bottom: -14.5px;
  width: 24px;
  height: 24px;
  background: #f29b9b;
  clip-path: polygon(0% 40%, 99% 40%, 49% 80%);
`;
export const ErrorTriangleUpsideDown = styled.div`
  z-index: 999;
  position: absolute;
  left: 30px;
  top: -14.5px;
  width: 24px;
  height: 24px;
  background: #f29b9b;
  clip-path: polygon(0% 40%, 99% 40%, 49% 80%);
  transform: rotate(180deg);
`;

const HintText = styled.span`
  margin-left: ${(props) => props.theme.spacing.xs}px;
  color: ${(props) => props.theme.colors.neutral500};
  font-size: 0.75rem;
`;

export const ExtraText = styled.span`
  color: ${(props) => props.theme.colors.neutral500};
  margin-left: 2px;
`;

export const ErrorText = styled.span`
  margin-left: ${(props) => props.theme.spacing.xs}px;
  color: ${(props) => props.theme.colors.neutral900};
  font-weight: 500;
`;

interface AuthInputProps extends Omit<ComponentProps<typeof Input>, 'error'> {
  label?: string | null;
  type?: string;
  error?: string | null;
  required?: boolean;
  hint?: string | null;
  extras?: string;
  disabled?: boolean;
  noHeightErrorContainer?: boolean;
  placeHolder?: string | null;
}
export interface ErrorProps {
  show: boolean;
  noHeight?: boolean;
}
interface InputProps {
  error: boolean;
}

export const AuthInput = forwardRef<HTMLInputElement, AuthInputProps>(
  (
    {
      label,
      required,
      error,
      type,
      hint,
      extras,
      disabled = false,
      placeHolder,
      noHeightErrorContainer = false,
      ...props
    }: AuthInputProps,
    ref
  ) => {
    return (
      <Container {...props}>
        {label ? (
          <Label className="text-m14">
            <span>
              {label}
              {required ? ' *' : ''}
            </span>
            <ExtraText>{extras}</ExtraText>
          </Label>
        ) : null}
        <Input
          disabled={disabled}
          error={error ? true : false}
          {...props}
          placeholder={placeHolder || undefined}
          type={type || 'text'}
          ref={ref}
        />
        <ErrorContainer
          noHeight={noHeightErrorContainer}
          show={error ? true : false}
        >
          {error ? (
            <>
              {/* <Image
                width={18}
                height={18}
                src="/icons/error.svg"
                alt="error icon"
              /> */}
              <ErrorText className="text-l12">{error}</ErrorText>
            </>
          ) : hint ? (
            <>
              <HintText>{hint}</HintText>
            </>
          ) : (
            ''
          )}
          <ErrorTriangle></ErrorTriangle>
        </ErrorContainer>
        <HintContainer>
          {hint ? (
            <>
              <HintText>{hint}</HintText>
            </>
          ) : (
            ''
          )}
        </HintContainer>
      </Container>
    );
  }
);
